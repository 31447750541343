//@import 'settings/colors';
//@import 'settings/breakpoints';
//@import 'settings/fonts';
//@import 'settings/other';
//@import 'settings/sizes';
//
//// TOOLS
//@import "../../node_modules/sass-mq/mq";
//@import 'tools/mixins';

//:root {
//  --amc-link: #69acdf;
//  --amc-textlink: #37739f;
//  --amc-hover: #69acdf;
//  --amc-landingPageHighlight: #69acdf;
//  --amc-landingPageMenu: #69acdf;
//}

body[data-bundle-id='marketing'] {

  .home-teaser-grid .teaser-tile .teaser-tile-image:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0, 0.5);
    content: '';
  }
}
